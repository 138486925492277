import logo from '../../images/logo.jpg'
import './styles.css'

const Header = () => {
  return (
    <header className="header">
      <a href="/"><h1><img src={logo} alt="Vila Serena" /></h1></a>
    </header>
  )
}

export default Header;
