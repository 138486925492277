import './styles.css'

import {useState} from 'react';

const Collapse = ({title, children}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="collapse">
      <button
       onClick={() => setOpen(!isOpen)}>
        <h4 className="collapse-title">{title} <span>{isOpen ? "-" : "+"}</span></h4>
      </button>
      {isOpen && <div className="collapse-content">{children}</div>}
    </div>
  )
}

export default Collapse;
