import "./styles.css";

import petrobras from "../../images/convenios/petrobras.png";
import omint from "../../images/convenios/omint.jpg";
import vivest from "../../images/convenios/vivest.png";
import cet from "../../images/convenios/cet.png";
import real from "../../images/convenios/real.png";
import vale from "../../images/convenios/vale.png";
import afresp from "../../images/convenios/afresp.png";
import amafresp from "../../images/convenios/amafresp.png";
import bradesco from "../../images/convenios/bradesco.png";
import cabesp from "../../images/convenios/cabesp.png";
import gama from "../../images/convenios/gama.png";
import intermedici from "../../images/convenios/intermedici.png";
import mediservice from "../../images/convenios/mediservice.png";
import metrus from "../../images/convenios/metrus.png";
import notredame from "../../images/convenios/notredame.png";
import blueMedSaude from "../../images/convenios/bluemed.png";

const Insurances = () => {
  return (
    <div className="insurances">
      <h3>Atendemos os Seguintes Convênios:</h3>
      <ul>
        <li>
          <img src={petrobras} alt="Petrobras" />
        </li>
        <li>
          <img src={omint} alt="Omint" />
        </li>
        <li>
          <img src={vivest} alt="Vivest" />
        </li>
        <li>
          <img src={cet} alt="CET" />
        </li>
        <li>
          <img src={real} alt="Real" />
        </li>
        <li>
          <img src={vale} alt="Vale" />
        </li>
        {/* <li>
          <img src={afresp} alt="Afresp" />
        </li> */}
        <li>
          <img className="format" src={amafresp} alt="Amafresp" />
        </li>
        <li>
          <img src={bradesco} alt="Bradesco" />
        </li>
        <li>
          <img src={cabesp} alt="Cabesp" />
        </li>
        <li>
          <img src={gama} alt="Gama" />
        </li>
        {/* <li>
          <img src={intermedici} alt="Intermedici" />
        </li> */}
        <li>
          <img className="format" src={blueMedSaude} alt="blueMedSaude" />
        </li>
        <li>
          <img src={mediservice} alt="Mediservice" />
        </li>
        <li>
          <img src={metrus} alt="Metrus" />
        </li>
        {/* <li>
          <img src={notredame} alt="Notredame" />
        </li> */}
      </ul>
    </div>
  );
};

export default Insurances;
