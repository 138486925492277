import "./styles/normalize.css";
import "./styles/App.css";
// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";

import whatsapp from "./images/whatsapp.png";
import slide1 from "./images/carousel/11463166-4c68-4234-b466-6a8a43f4f5aa.jpg";
// import slide2 from "./images/carousel/7-enfermagem.jpg";
import slide3 from "./images/carousel/IMG_4707.jpg";
// import slide4 from "./images/carousel/IMG-20200818-WA0026-1.jpg";
import slide4 from "./images/carousel/CasaVila.png";
import slide5 from "./images/carousel/piscina-vssp.jpg";

import Header from "./components/Header";
// import Button from "./components/Button";
import Center from "./components/Center";
import Insurances from "./components/Insurances";
import Content from "./components/Content";
import Faq from "./components/Faq";

import { Splide, SplideSlide } from "@splidejs/react-splide";

function App() {
  return (
    <main className="App">
      <Header />
      <Center>
        <p className="description">
          Vila Serena São Paulo <br />
          Centro para Tratamento de Dependência Química
        </p>
      </Center>
      <Center>
        <Content />
      </Center>
      {/* <Center>
        <Button
          icon={whatsapp}
          href="https://api.whatsapp.com/send?phone=551159289094"
          text="WhatsApp - (11) 5928-9094"
        />
      </Center> */}
      {/* <Center>
        <h2>
          Protocolos de COVID da Vila Serena fiscalizado pela ANVISA - 08/10/20
        </h2>
      </Center> */}
      <div className="slider">
        <Splide
          aria-label="My Favorite Images"
          options={{
            type: "fade",
            perPage: 1,
            arrows: true,
            autoplay: true,
            interval: 3000,
            rewind: true,
            pauseOnHover: true,
            breakpoints: {
              640: {
                arrows: false,
              },
            },
          }}
        >
          <SplideSlide>
            <img src={slide1} alt="Image 1" />
          </SplideSlide>
          {/* <SplideSlide>
            <img src={slide2} alt="Image 1" />
          </SplideSlide> */}
          <SplideSlide>
            <img src={slide3} alt="Image 1" />
          </SplideSlide>
          <SplideSlide>
            <img src={slide4} alt="Image 1" />
          </SplideSlide>
          <SplideSlide>
            <img src={slide5} alt="Image 1" />
          </SplideSlide>
        </Splide>
      </div>
      <Center>
        <Insurances />
      </Center>
      <Faq />
    </main>
  );
}

export default App;
