import "./styles.css";

import Collapse from "../Collapse";
import Center from "../Center";

import warning from "../../images/warning.png";
import editora from "../../images/editora.jpeg";
import facebook from "../../images/facebook.png";
import twitter from "../../images/twitter.png";
import instagram from "../../images/instagram.png";

const Faq = () => {
  return (
    <div className="faq">
      <Collapse title="Planos de saúde que servem Vila Serena - Convênios.">
        <p>
          Amafresp/Afresp – Bradesco Seguros – Cabesp – CET – Vivesp/Fundação
          CESP – Gama Saúde – Mediservice – Metrus – Omint – Pasa Vale – Real
          Grandeza – Blue Med Saúde – Petrobras
        </p>
      </Collapse>
      <Collapse title="Como ajudar um dependente?">
        <p>Deixe-o assumir as consequências do seu comportamento.</p>
      </Collapse>
      <Collapse title="Metodologia">
        <p>
          Tratamento grupal baseado nos 12 Passos de Alcoólicos e Narcóticos
          Anônimos durante um período de internação de 60-90 dias ou regime
          ambulatorial, ambos com um plano de apoio por um ano.
        </p>
      </Collapse>
      <Collapse title="Programação para a família">
        <ul>
          <li>Programa Familiar + Visita - Sábado das 09:00 às 12:00 horas.</li>
        </ul>
        {/* <Center>
          <img src={warning} alt="atenção" width={50} />
          <p>Visitas - Tendo em vista a pandemia não aceitamos</p>
        </Center> */}
      </Collapse>
      <Collapse title="Tour virtual de Vila Serena">
        <a
          href="https://tour-virtual.vilaserenasp.com.br/"
          target="_blank"
          rel="noreferrer">
          Clicar aqui para tour virtual de Vila Serena
        </a>
      </Collapse>
      <Collapse title="Contatos - WhatsApp">
        <p>
          (11) 4327-0708 / (11) 4327-5367
          <br />
          <a
            href="https://api.whatsapp.com/send?phone=5511968368585"
            target="_blank"
            rel="noreferrer">
            WhatsApp – (11) 96836-8585
          </a>
          <br />
          <a href="mailto:vilasaopaulo@vilaserenasp.com.br">
            vilasaopaulo@vilaserenasp.com.br
          </a>
          <br />
          Avenida da Praia, 132 | 151- Vila Represa, São Paulo - SP, 04826-360
          <br />
        </p>
      </Collapse>
      <Collapse title="Redes sociais">
        <p>
          Venha visitar as páginas de Vila Serena São Paulo e deixe uma mensagem
          e/ou foto:
        </p>
        <div className="social-media">
          <a
            href="https://twitter.com/vilaserenasp"
            target="_blank"
            rel="noreferrer">
            <img src={twitter} alt="Twitter" />
            Twitter
          </a>
          <a
            href="https://www.facebook.com/vilaserenasp"
            target="_blank"
            rel="noreferrer">
            <img src={facebook} alt="Facebook" />
            Facebook
          </a>
          <a
            href="https://www.instagram.com/vilaserenasp/"
            target="_blank"
            rel="noreferrer">
            <img src={instagram} alt="Instagram" />
            Instagram
          </a>
        </div>
      </Collapse>
      <Collapse title="Sugestões do que levar">
        <ul>
          <li>3 calças compridas</li>
          <li>3 calças de moleton</li>
          <li>6 camisas ou camisetas</li>
          <li>2 blusas para frio</li>
          <li>1 casaco / jaqueta</li>
          <li>2 bermudas</li>
          <li>2 pijamas</li>
          <li>1 roupão</li>
          <li>7 peças íntimas</li>
          <li>6 pares de meias</li>
          <li>1 par de tênis</li>
          <li>1 par de chinelos</li>
          <li>1 par de sapatos</li>
          <li>1 traje de banho para piscina – shorts (masc.) maiô (fem.)</li>
          <li>1 toalha de banho para piscina</li>
          <li>
            Creme dental / escova de dente / escova de cabelo ou pente /
            sabonete liquido.
          </li>
          <li>Repelente em creme</li>
          <li>Outros itens para higiene pessoal (que não contenham álcool)</li>
          <li>Desodorantes: bastão, roll-on ou creme</li>
          <li>Dinheiro para compras extras.</li>
          <li>Lenço de papel / Garrafa para água (Squeeze)</li>
          <li>2 cadernos e 3 canetas.</li>
          <li>
            Objetos de valor devem ser confinados à guarda da administração.
          </li>
        </ul>
        <br />
        <strong>O que não deve ser levado para a internação?</strong>
        <ul>
          <li>
            Qualquer produto que contenha álcool (acetona, desodorantes,
            perfumes, etc.)
          </li>
          <li>
            Qualquer produto de higiene em pó (ex: talco de corpo, pé, etc.)
          </li>
          <li>
            Qualquer substância química que provoque alteração no comportamento
            como: maconha, bebidas alcoólicas, psicotrópicos, éter,
            anorexígenos, anti-distônicos, etc. (Encontrada uma dessas
            substâncias na posse do residente, durante o tratamento, ele será
            imediatamente desligado do centro).
          </li>
          <li>
            Instrumentos cortantes como facas, canivetes, lâminas de barbear.
          </li>
          <li>Revistas, jornais livros, óculos escuros e boné.</li>
          <li>Rádio, TV, gravador e aparelhos de som, notebook e celular.</li>
          <li>Roupas transparentes, curtas e decotadas.</li>
          <li>
            Caso o dependente esteja fazendo uso de algum medicamento prescrito
            por médico, trazer o mesmo juntamente com a receita médica e
            entregar ao enfermeiro de plantão.
          </li>
          <li>Veículo e objetos de valor.</li>
        </ul>
      </Collapse>
      <Collapse title="Como chegar">
        <p>Acesso por ônibus:</p>
        <p>
          <b>Terminal Anhangabaú</b>
        </p>
        <p>
          Pegar ônibus Terminal Varginha – descer na parada Mário Martins,
          sentido bairro na Av. Atlântica, entrar ao lado do Buffet Carvalho e
          já estará na Rua Nicolau, ir até o nº 859 (Vila Serena)
        </p>
        <p>
          <b>Terminal Jabaquara</b>
        </p>
        <p>
          Pegar ônibus Terminal Capelinha ou Jardim Guarujá, descer na parada
          Victor Manzini e pegar qualquer ônibus que passe pela Av. Atlântica,
          entrar ao lado do Buffet Carvalho e já estará na Rua Nicolau, ir até o
          nº 859 (Vila Serena). (todas as linhas de ônibus cor azul)
        </p>
        <p>
          <b>Acesso Metro e Trem:</b>
        </p>
        <p>
          Acessar a linha amarela sentido Pinheiros descer na estação Pinheiros
          – pegar o trem sentido Grajaú e descer na estação socorro. Dirija-se a
          parada de ônibus Victor Manzini e pegar qualquer ônibus que passe pela
          Av. Atlântica, entrar ao lado do Buffet Carvalho e já estará na Rua
          Nicolau, ir até o nº 859 (Vila Serena). (todas as linhas de ônibus na
          cor azul)
        </p>
        <p>
          Atenção – nos ônibus citados a cima (azul) esta marcado que o
          itinerário é pela Av. Atlântica
        </p>
        <p>
          <b>Acesso por carro:</b>
        </p>

        <iframe
          loading="lazy"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3653.0306555711763!2d-46.71184388576985!3d-23.71059928460904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce4e4b2fbcca0f%3A0xb2aeccf04eab5e01!2sR.+Nicolau+Alayon%2C+859+-+Interlagos%2C+S%C3%A3o+Paulo+-+SP!5e0!3m2!1sen!2sbr!4v1463586278869"
          width="600"
          height="450"
          data-mce-fragment="1"></iframe>
        <p>
          É sugerido vir pela Avenida Interlagos e na altura do número 6870,
          entrar na Rua Nicolau Alayon e ir até o 859.
        </p>
      </Collapse>
      <Collapse title="Editora Vila Serena">
        <Center>
          <img src={editora} alt="Editora Vila Serena" />
          <p>
            A Editora Vila Serena funciona desde os anos 80 como um meio de
            oferecer ao público livros em português relacionados ao processo de
            recuperação.
          </p>
          <a
            href="https://www.instagram.com/editora_vilaserena/"
            target="_blank"
            rel="noreferrer">
            Clique aqui para mais informações
          </a>
        </Center>
      </Collapse>

      <Collapse title="Outras Vilas Serenas RJ - CE - MG - BA">
        <p>Visitar os sites das outra unidades de Vila Serena no Brasil:</p>
        <a
          href="http://www.vilaserenabahia.com.br/"
          target="_blank"
          rel="noreferrer">
          Vila Serena Bahia
        </a>
        <br />
        <a
          href="http://vilaserena.com.br/rio_new/"
          target="_blank"
          rel="noreferrer">
          Vila Serena Rio de Janeiro
        </a>
        <br />
        <a
          href="http://www.vilaserena.com.br/PC"
          target="_blank"
          rel="noreferrer">
          Vila Serena Poços de Caldas
        </a>
        <br />
        <a
          href="http://vilaserenafortaleza.com.br/"
          target="_blank"
          rel="noreferrer">
          Vila Serena Fortaleza
        </a>
      </Collapse>
      <Collapse title="Filosofia e Fundamentos">
        <strong>Sumários:</strong>
        <ol>
          <li>
            Uma página selecionada como recursos de Metodologia, Executores,
            Equilíbrio e Tratamento / Finanças. <br />
            <a href="https://www.vilaserenasp.com.br/documents/1_Vila-Serena-Princípios-Fundamentais.pdf">
              Clique Aqui
            </a>
          </li>
          <li>
            Uma folha enfatizando o papel central do grupo de tratamento. <br />
            <a href="https://www.vilaserenasp.com.br/documents/2_Filosofia-de-Vila-Serena-_1_.pdf">
              Clique Aqui
            </a>
          </li>
          <li>
            A evolução e os elementos de tratamento em Vila Serena, comentando
            sobre Internação, Pós-Tratamento, Treinamento, Site e Programa
            Familiar. 7 páginas. <br />
            <a href="https://www.vilaserenasp.com.br/documents/3_Evolucao-de-trat-VSSP_10_Fev_15.pdf">
              Clique Aqui
            </a>
          </li>
          <li>
            Duas páginas enfatizando o papel da estória. <br />
            <a href="https://www.vilaserenasp.com.br/documents/4_Historia_completo-convertido.pdf">
              Clique Aqui
            </a>
          </li>
          <li>
            Uma página sobre a visão recomendada em Vila Serena: Poética. <br />
            <a href="https://www.vilaserenasp.com.br/documents/5_visao_poetico-convertido.pdf">
              Clique Aqui
            </a>
          </li>
          <li>
            Phronésis ou características do terapeuta. 2 página. <br />
            <a href="https://www.vilaserenasp.com.br/documents/6_Phronesis_Port-convertido.pdf">
              Clique Aqui
            </a>
          </li>
          <li>
            Psicologia em Vila Serena: Psicologia Profunda. 7 páginas. <br />
            <a href="https://www.vilaserenasp.com.br/documents/7_PsicologiaProfunda-convertido.pdf">
              Clique Aqui
            </a>
          </li>
          <li>
            Teoria Geral de Sistemas e Psicologia Arquitetônica aplicada ao
            grupo. 7 páginas. <br />
            <a href="https://www.vilaserenasp.com.br/documents/8_TGS-e-PsiqArq-Grupo-convertido.pdf">
              Clique Aqui
            </a>
          </li>
          <li>
            Um documento complexo de 11 páginas com comentários de John Burns,
            enfatizando a leitura e o sonho, consciência / inconsciência,
            imaginação, imaginação, natureza e aplicação em tratamento. 11
            páginas. <br />
            <a href="https://www.vilaserenasp.com.br/documents/9_Ao_meu_ver_16_junho_11.pdf">
              Clique Aqui
            </a>
          </li>
          <li>
            Documento completo de 37 páginas que descrevem a história e a
            interface do Modelo Minnesota com Psicologia Arquitetônica e
            Comentários de James Hillman.
            <br />
            <a href="https://www.vilaserenasp.com.br/documents/10_modminn.pdf">
              Clique Aqui
            </a>
            <br />
            <a href="https://www.vilaserenasp.com.br/documents/11_modminn-en.pdf">
              Clique aqui para uma versão em inglês
            </a>
          </li>
          <li>
            Livro Cordel de Vila Serena <br />
            <a href="https://www.vilaserenasp.com.br/documents/Cordel-Severino.pdf">
              Clique aqui
            </a>
          </li>
        </ol>
      </Collapse>
      <Collapse title="Site in English">
        <a
          href="http://vilaserena.com.br/sp4/"
          target="_blank"
          rel="noreferrer">
          Click here
        </a>{" "}
        to access a Vila Serena site in English.
      </Collapse>
    </div>
  );
};

export default Faq;
