import "./styles.css";

const Content = () => {
  return (
    <section className="content">
      <strong>Para mais informações</strong>
      <p>
        <a
          href="https://api.whatsapp.com/send?phone=5511968368585"
          target="_blank"
          rel="noreferrer">
          (11) 96836-8585
        </a>
        &nbsp;/ (11) 4327-0708
        <br />
        <a href="mailto:vilasaopaulo@vilaserenasp.com.br">
          vilasaopaulo@vilaserenasp.com.br
        </a>
        <br />
        Avenida da Praia, 132 | 151- Vila Represa, São Paulo - SP, 04826-360
        <br />
      </p>
    </section>
  );
};

export default Content;
